import { FaFacebook } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { BiLogoLinkedin } from "react-icons/bi";
import { FaXTwitter } from "react-icons/fa6";

export const SOCIAL_MEDIA_LINKS = [
  {
    icon: <FaFacebook style={{ fontSize: "25px" }} />,
    path: "https://www.facebook.com/profile.php?id=100089134988451",
  },
  {
    icon: (
      <AiFillInstagram
        style={{ fontSize: "28px", position: "relative", top: "0px" }}
      />
    ),
    path: "https://www.instagram.com/skynoveau.in/",
  },
  {
    icon: (
      <BiLogoLinkedin
        style={{ fontSize: "28px", position: "relative", top: "-1px" }}
      />
    ),
    path: "https://www.linkedin.com/company/skynoveau-technology/",
  },
  {
    icon: <FaXTwitter style={{ fontSize: "22px" }} />,
    path: "https://x.com/karthiknoveau",
  },
];

export const FOOTER_MENU_LINKS = [
  {
    title: "QUICK LINKS",
    links: [
      { name: "Home", path: "/home" },
      { name: "About", path: "/about" },
      { name: "Service", path: "/services" },
      { name: "Contact", path: "/contact" },
    ],
  },
  {
    title: "QUICK CONTACT",
    links: [
      { name: "Feel free to call" },
      { name: "+91 9360375911" },
      { name: "Connect us", path: "/contact" },
    ],
  },
  {
    title: "OUR ADDRESS",
    links: [
      { name: "3/51, Near Collector Office" },
      { name: "Namakkal, Tamil Nadu 637003" },
    ],
  },
];
