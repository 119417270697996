import React from "react";

import { HeaderBottomDropDown } from "./mobile/bottom_dropdown/index.jsx";
import { HeaderMaxWidthDropDown } from "./desktop/maxwidth_dropdown/index.jsx";

import styles from "./navbar.style.module.css";

export const Header = () => {
  return (
    <React.Fragment>
      <div className={styles.deskHeader}>
        <HeaderMaxWidthDropDown />
      </div>
      <div className={styles.mobileHeader}>
        <HeaderBottomDropDown />
      </div>
    </React.Fragment>
  );
};
