import React, { useEffect, useState } from "react";
import { Sling as Hamburger } from "hamburger-react";
import { useLocation, useNavigate } from "react-router-dom";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { RiArrowRightLine } from "react-icons/ri";

import { MENU_LIST } from "./constants";

import Logo from "@images/logo/logo.png";
import ArrowIcon from "@icons/arrow.svg";

import styles from "./navbar.style.module.css";
import { EmptySpace } from "@components/navbar/empty_space";
import "./hamburger.override.css";

export const HeaderBottomDropDown = () => {
  const navigate = useNavigate();
  const locatiion = useLocation();

  const [dropdownTitles, setDropdownTitles] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [navHeight, setNavHeight] = useState("60px");
  // const [navColor, setNavColor] = useState("transparent");

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  const listenScrollEvent = () => {
    // window.scrollY > 10 && setNavColor("black");
    window.scrollY > 10 ? setNavHeight("70px") : setNavHeight("60px");
  };

  const onMenuClick = (menu) => {
    if (dropdownTitles.length) {
      setDropdownTitles((prev) => {
        if (prev.includes(menu.name)) {
          return prev.filter((item) => item !== menu.name);
        }
        return [...prev, menu.name];
      });
    } else {
      let list = [menu.name];
      setDropdownTitles(list);
    }
  };

  return (
    <React.Fragment>
      <div className={styles.headerContainer}>
        {/* header */}
        <div
          className={styles.topHeaderWrapper}
          style={{
            // backgroundColor: navColor,
            height: navHeight,
            transition: "all 0.5s",
          }}
        >
          <div className={styles.topHeaderContainer}>
            <div className={styles.leftInfo}>
              <img
                className={styles.logo}
                src={Logo}
                alt="logo"
                onClick={() => navigate("/home")}
              />
              <div
                className={styles.companyName}
                onClick={() => navigate("/home")}
              >
                <p>Skynoveau</p>
                <p>Technology</p>
              </div>
            </div>
            <div
              className={`${styles.rightInfo} hamburgerMenu ${
                isOpen ? "closeIcon" : "openIcon"
              }`}
            >
              <Hamburger
                direction="right"
                duration={0.7}
                size={29.2}
                toggled={isOpen}
                toggle={() => setIsOpen(!isOpen)}
              />
            </div>
          </div>
        </div>

        <div
          className={`${styles.bottomHeaderWrapper} ${
            isOpen && styles.showDrawer
          }`}
        >
          <div className={styles.bottomHeaderContainer}>
            {MENU_LIST.map((menu) => {
              if (menu?.dropdown) {
                return null;
              }
              return (
                <div
                  className={styles.menuItemContainer}
                  onClick={() => navigate(menu.path)}
                >
                  <div
                    className={`${styles.item} ${
                      menu.path === locatiion.pathname && styles.active
                    }`}
                  >
                    {menu.name}
                  </div>
                </div>
              );
            })}

            {/* dropdown */}
            {MENU_LIST.map((menu) => {
              if (!menu?.dropdown) {
                return null;
              }
              return (
                <div className={styles.dropdownMenu}>
                  <div
                    className={styles.menuItemContainer}
                    onClick={() => onMenuClick(menu)}
                  >
                    <div className={styles.item}>{menu.name}</div>
                    <MdOutlineArrowForwardIos
                      className={`${styles.icon} ${
                        dropdownTitles.includes(menu.name) && styles.animateIcon
                      }`}
                    />
                  </div>

                  <div
                    className={`${styles.dropdownWrapper} ${
                      dropdownTitles.includes(menu.name) &&
                      styles.showDropdownContainer
                    }`}
                  >
                    <div className={styles.dropdownContainer}>
                      {menu.dropdown.map((dropdownItem) => {
                        return (
                          <div
                            className={`${styles.menuItem} ${
                              styles.subMenuItem
                            } ${
                              dropdownItem.path === locatiion.pathname &&
                              styles.active
                            }`}
                            onClick={() => navigate(dropdownItem.path)}
                          >
                            {dropdownItem.name}

                            <img
                              src={ArrowIcon}
                              className={styles.icon}
                              alt="icon"
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}

            <div className={styles.lastMenuWrapper}>
              <div
                className={styles.lastMenuContainer}
                onClick={() => {
                  navigate("/plans-pricing");
                }}
              >
                Plans and Pricing
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* --------- empty space --------- */}
      <EmptySpace height={navHeight} />
    </React.Fragment>
  );
};
