export const BLOG_CATEGORIES = [
  { name: "All", isActive: true },
  { name: "Event posts" },
];
export const BLOG_DATA = [
  {
    id: "blog_1",
    category: "Event posts",
    title: "blog_1 5 Elements of an Enterprise Risk Management Framework",
    image:
      "https://cdn-docel.nitrocdn.com/aEGOFDNfbZbQhInYvdvpEYJadKCNEHnx/assets/images/optimized/rev-0a09994/www.onboardmeetings.com/wp-content/uploads/2024/08/Enterprise-Risk-Management-Framework-2-700x388.jpeg",
    intro:
      "Risk plays a role in each and every path an organization chooses. Before pushing forward with any decision executive  leadership must consider the amount of resources each activity will usse...",
    fullDetails: `<p><span style="color: #0f0f0f;">Some companies take product education to a whole new level. While&nbsp;43% of survey respondents offer in-app product tours and checklists once to new users, more than a third of respondents make them always available.</span></p>
<p><span style="color: #0f0f0f;">So whether a user is on their first or 100th log-in, they can access product tours and checklists directly from the&nbsp;<a style="color: #0f0f0f;" href="https://productfruits.com/product/life-ring-button">Life Ring Button</a>&nbsp;to guide them through some of your product&rsquo;s key features at any time.&nbsp;</span></p>
<p><span style="color: #0f0f0f;">This recognizes that onboarding isn&rsquo;t a one-time task that&rsquo;s either &ldquo;done&rdquo; or &ldquo;not done&rdquo;. For some users it can be an ongoing journey with your product, as they start using new features, integrations, or processes.</span></p>
<h3 class="wp-block-heading"><span style="color: #0f0f0f;">30% of companies make product tours and checklists available when launching new features</span></h3>
<p><span style="color: #0f0f0f;">Almost one-third of respondents to our survey said they make&nbsp;<a style="color: #0f0f0f;" href="https://productfruits.com/product/tours">product tours</a>&nbsp;and checklists available to users when they launch new features. They use things that are traditionally seen as &ldquo;onboarding tools&rdquo; as part of their ongoing product education efforts.</span></p>
<p><span style="color: #0f0f0f;">If you have a user onboarding platform like Product Fruits, you can use its features at other times in the customer lifecycle &mdash; not just during their first use or trial period.</span></p>
<h3 class="wp-block-heading"><span style="color: #0f0f0f;">Key takeaway: Think flexibility 💡</span></h3>
<p><span style="color: #0f0f0f;">The traditional onboarding flow focuses on your newest users &mdash;&nbsp;<a style="color: #0f0f0f;" href="https://productfruits.com/product/hints">tooltips</a>&nbsp;and&nbsp;<a style="color: #0f0f0f;" href="https://productfruits.com/product/onboarding-checklist">checklists</a>&nbsp;pop up on first use.&nbsp;</span></p>
<p><span style="color: #0f0f0f;">Consider making them always available instead, so users can work through at their own pace. You may even find users revisit parts of your onboarding multiple times, as they get to grips with different areas of your product.</span></p>
<p><span style="color: #0f0f0f;">This flexibility can help boost product and onboarding engagement, and reduce support tickets.&nbsp;</span></p>
<p>&nbsp;</p>
<h2 class="wp-block-heading"><span style="color: #0f0f0f;">Think outside the app: Other elements of user onboarding</span></h2> `,
    date: "August 2, 2024",
  },
  {
    id: "blog_2",
    title:
      "blog_2 Interactive walkthrough vs product tour: benefits and differences",
    image:
      "https://cdn-docel.nitrocdn.com/aEGOFDNfbZbQhInYvdvpEYJadKCNEHnx/assets/images/optimized/rev-0a09994/www.onboardmeetings.com/wp-content/uploads/2024/08/Enterprise-Risk-Management-Framework-2-700x388.jpeg",
    intro:
      "Risk plays a role in each and every path an organization chooses. Before pushing forward with any decision executive  leadership must consider the amount of resources each activity will usse...",
    fullDetails:
      "Risk plays a role in each and every path an organization chooses. Before pushing forward with any decision executive  leadership must consider the amount of resources each activity will usse...",
    date: "August 2, 2024",
  },
  {
    id: "blog_3",
    title: "blog_3 Decoding User Onboarding: Trends You Need To Know",
    image:
      "https://cdn-docel.nitrocdn.com/aEGOFDNfbZbQhInYvdvpEYJadKCNEHnx/assets/images/optimized/rev-0a09994/www.onboardmeetings.com/wp-content/uploads/2024/08/Enterprise-Risk-Management-Framework-2-700x388.jpeg",
    intro:
      "Risk plays a role in each and every path an organization chooses. Before pushing forward with any decision executive  leadership must consider the amount of resources each activity will usse...",
    fullDetails:
      "Risk plays a role in each and every path an organization chooses. Before pushing forward with any decision executive  leadership must consider the amount of resources each activity will usse...",
    date: "August 2, 2024",
  },
  {
    id: "blog_4",
    title: "blog_4 5 Elements of an Enterprise Risk Management Framework",
    image:
      "https://cdn-docel.nitrocdn.com/aEGOFDNfbZbQhInYvdvpEYJadKCNEHnx/assets/images/optimized/rev-0a09994/www.onboardmeetings.com/wp-content/uploads/2024/08/Enterprise-Risk-Management-Framework-2-700x388.jpeg",
    intro:
      "Risk plays a role in each and every path an organization chooses. Before pushing forward with any decision executive  leadership must consider the amount of resources each activity will usse...",
    fullDetails:
      "Risk plays a role in each and every path an organization chooses. Before pushing forward with any decision executive  leadership must consider the amount of resources each activity will usse...",
    date: "August 2, 2024",
  },
  {
    id: "blog_5",
    title:
      "blog_5 Interactive walkthrough vs product tour: benefits and differences",
    image:
      "https://cdn-docel.nitrocdn.com/aEGOFDNfbZbQhInYvdvpEYJadKCNEHnx/assets/images/optimized/rev-0a09994/www.onboardmeetings.com/wp-content/uploads/2024/08/Enterprise-Risk-Management-Framework-2-700x388.jpeg",
    intro:
      "Risk plays a role in each and every path an organization chooses. Before pushing forward with any decision executive  leadership must consider the amount of resources each activity will usse...",
    fullDetails:
      "Risk plays a role in each and every path an organization chooses. Before pushing forward with any decision executive  leadership must consider the amount of resources each activity will usse...",
    date: "August 2, 2024",
  },
  {
    id: "blog_6",
    title: "blog_6 Decoding User Onboarding: Trends You Need To Know",
    image:
      "https://cdn-docel.nitrocdn.com/aEGOFDNfbZbQhInYvdvpEYJadKCNEHnx/assets/images/optimized/rev-0a09994/www.onboardmeetings.com/wp-content/uploads/2024/08/Enterprise-Risk-Management-Framework-2-700x388.jpeg",
    intro:
      "Risk plays a role in each and every path an organization chooses. Before pushing forward with any decision executive  leadership must consider the amount of resources each activity will usse...",
    fullDetails:
      "Risk plays a role in each and every path an organization chooses. Before pushing forward with any decision executive  leadership must consider the amount of resources each activity will usse...",
    date: "August 2, 2024",
  },
  {
    id: "blog_7",
    title: "blog_7 5 Elements of an Enterprise Risk Management Framework",
    image:
      "https://cdn-docel.nitrocdn.com/aEGOFDNfbZbQhInYvdvpEYJadKCNEHnx/assets/images/optimized/rev-0a09994/www.onboardmeetings.com/wp-content/uploads/2024/08/Enterprise-Risk-Management-Framework-2-700x388.jpeg",
    intro:
      "Risk plays a role in each and every path an organization chooses. Before pushing forward with any decision executive  leadership must consider the amount of resources each activity will usse...",
    fullDetails:
      "Risk plays a role in each and every path an organization chooses. Before pushing forward with any decision executive  leadership must consider the amount of resources each activity will usse...",
    date: "August 2, 2024",
  },
  {
    id: "blog_8",
    title:
      "blog_8 Interactive walkthrough vs product tour: benefits and differences",
    image:
      "https://cdn-docel.nitrocdn.com/aEGOFDNfbZbQhInYvdvpEYJadKCNEHnx/assets/images/optimized/rev-0a09994/www.onboardmeetings.com/wp-content/uploads/2024/08/Enterprise-Risk-Management-Framework-2-700x388.jpeg",
    intro:
      "Risk plays a role in each and every path an organization chooses. Before pushing forward with any decision executive  leadership must consider the amount of resources each activity will usse...",
    fullDetails:
      "Risk plays a role in each and every path an organization chooses. Before pushing forward with any decision executive  leadership must consider the amount of resources each activity will usse...",
    date: "August 2, 2024",
  },
  {
    id: "blog_9",
    title: "blog_9 Decoding User Onboarding: Trends You Need To Know",
    image:
      "https://cdn-docel.nitrocdn.com/aEGOFDNfbZbQhInYvdvpEYJadKCNEHnx/assets/images/optimized/rev-0a09994/www.onboardmeetings.com/wp-content/uploads/2024/08/Enterprise-Risk-Management-Framework-2-700x388.jpeg",
    intro:
      "Risk plays a role in each and every path an organization chooses. Before pushing forward with any decision executive  leadership must consider the amount of resources each activity will usse...",
    fullDetails:
      "Risk plays a role in each and every path an organization chooses. Before pushing forward with any decision executive  leadership must consider the amount of resources each activity will usse...",
    date: "August 2, 2024",
  },
  {
    id: "blog_10",
    title: "blog_10 5 Elements of an Enterprise Risk Management Framework",
    image:
      "https://cdn-docel.nitrocdn.com/aEGOFDNfbZbQhInYvdvpEYJadKCNEHnx/assets/images/optimized/rev-0a09994/www.onboardmeetings.com/wp-content/uploads/2024/08/Enterprise-Risk-Management-Framework-2-700x388.jpeg",
    intro:
      "Risk plays a role in each and every path an organization chooses. Before pushing forward with any decision executive  leadership must consider the amount of resources each activity will usse...",
    fullDetails:
      "Risk plays a role in each and every path an organization chooses. Before pushing forward with any decision executive  leadership must consider the amount of resources each activity will usse...",
    date: "August 2, 2024",
  },
  {
    id: "blog_11",
    title:
      "blog_11 Interactive walkthrblog_11 ugh vs product tour: benefits and differences",
    image:
      "https://cdn-docel.nitrocdn.com/aEGOFDNfbZbQhInYvdvpEYJadKCNEHnx/assets/images/optimized/rev-0a09994/www.onboardmeetings.com/wp-content/uploads/2024/08/Enterprise-Risk-Management-Framework-2-700x388.jpeg",
    intro:
      "Risk plays a role in each and every path an organization chooses. Before pushing forward with any decision executive  leadership must consider the amount of resources each activity will usse...",
    fullDetails:
      "Risk plays a role in each and every path an organization chooses. Before pushing forward with any decision executive  leadership must consider the amount of resources each activity will usse...",
    date: "August 2, 2024",
  },
  {
    id: "blog_12",
    title: "blog_12  Decoding User Onboarding: Trends You Need To Know",
    image:
      "https://cdn-docel.nitrocdn.com/aEGOFDNfbZbQhInYvdvpEYJadKCNEHnx/assets/images/optimized/rev-0a09994/www.onboardmeetings.com/wp-content/uploads/2024/08/Enterprise-Risk-Management-Framework-2-700x388.jpeg",
    intro:
      "Risk plays a role in each and every path an organization chooses. Before pushing forward with any decision executive  leadership must consider the amount of resources each activity will usse...",
    fullDetails:
      "Risk plays a role in each and every path an organization chooses. Before pushing forward with any decision executive  leadership must consider the amount of resources each activity will usse...",
    date: "August 2, 2024",
  },
];
