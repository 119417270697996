import React from "react";
import {
  GlobalCanvas,
  SmoothScrollbar as ScrollProvider,
} from "@14islands/r3f-scroll-rig";

export const SmoothScrollbar = ({ children }) => {
  return (
    <React.Fragment>
      <GlobalCanvas style={{ zIndex: -1, width: "100%" }}></GlobalCanvas>
      <ScrollProvider damping={20} thumbMinSize={20}>
        {(bind) => <span {...bind}>{children}</span>}
      </ScrollProvider>
    </React.Fragment>
  );
};
