import React from "react";
import { ParallaxProvider } from "react-scroll-parallax";

import { SmoothScrollbar } from "@components/scroll_widgets/smooth_scrollbar";

export const EntryProvider = ({ children }) => {
  return (
    <ParallaxProvider>
      <SmoothScrollbar>{children}</SmoothScrollbar>
    </ParallaxProvider>
  );
};
