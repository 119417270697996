import React from "react";

import styles from "./loader.style.module.css";

export const SimpleLoader = () => {
  return (
    <div className={styles.loaderContainer}>
      <div className={styles.circle}></div>
      <p>Loading</p>
    </div>
  );
};
