import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { Header } from "@components/navbar";
import { Footer } from "@components/footer";
import { SimpleLoader as Loader } from "@components/loaders/simple_loader";
import { EntryProvider } from "@components/entry_provider";
import { WhataApp } from "@components/whatsapp";
import SuspenseLoader from "@components/lazy_loaders/suspense_lazy_loader";
import { Blog } from "@components/blog";
import { BlogDetails } from "@components/blog/blog_details";

import "@common/global.override.css";
import "@common/global.css";

// Lazy-loaded components
const NotFound = SuspenseLoader(
  React.lazy(() => import("@components/page_not_found"))
);
const Home = SuspenseLoader(React.lazy(() => import("@pages/home")));
const About = SuspenseLoader(React.lazy(() => import("@pages/about")));
const Contact = SuspenseLoader(React.lazy(() => import("@pages/contact")));
const Services = SuspenseLoader(React.lazy(() => import("@pages/service")));
const Pricing = SuspenseLoader(React.lazy(() => import("@pages/pricing")));

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    setIsLoader(true);
    if (location.pathname === "/") {
      navigate("/home");
    }

    setTimeout(() => {
      setIsLoader(false);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <EntryProvider>
      {isLoader && <Loader />}
      {!isLoader && (
        <React.Fragment>
          <Header />
          <WhataApp />

          {/* routes */}
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/plans-pricing" element={<Pricing />} />
            <Route path="/blog/:id" element={<BlogDetails />} />
            <Route path="*" element={<NotFound />} />
          </Routes>

          <Footer />
        </React.Fragment>
      )}
    </EntryProvider>
  );
}

export default App;
