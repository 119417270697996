import { useNavigate, useParams } from "react-router-dom";
import { MdOutlineArrowForwardIos } from "react-icons/md";

import { BLOG_DATA } from "../constants";

import styles from "./blogdetails.style.module.css";

export const BlogDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const blogInfo = BLOG_DATA.find((blog) => blog.id === id);

  return (
    <div className={styles.blogWrapper}>
      <div className={styles.blogContainer}>
        <div className={styles.header}>
          <p className={styles.blogText} onClick={() => navigate("/blog")}>
            Blogs
          </p>
          <MdOutlineArrowForwardIos className={styles.icon} />
          <p className={styles.blogTitle}>{blogInfo.title}</p>
        </div>
        <div className={styles.blogDetails}>
          <p className={styles.blogDate}>{blogInfo.date}</p>
          <div className={styles.fullDetails}>
            <div dangerouslySetInnerHTML={{ __html: blogInfo.fullDetails }} />
          </div>
        </div>
      </div>
    </div>
  );
};
